import React from "react"
import { graphql } from "gatsby"
import { DiscussionEmbed } from "disqus-react"

import Layout from "components/layout"
import Markdown from "components/markdown"
import SEO from "components/seo"

const BlogPostPage = ({ data }) => {
  const {
    body: {
      childMarkdownRemark: { html },
    },
    id,
    slug,
    title,
    // updatedAt,
  } = data.contentfulBlogPost

  const disqusConfig = {
    identifier: slug,
    title,
  }

  const shortname = "christina-wiebe"

  return (
    <Layout>
      <SEO title={title} />
      <h1>{title}</h1>
      {/* <p>{new Date(updatedAt).toLocaleDateString()}</p> */}
      <Markdown html={html} />
      <DiscussionEmbed shortname={shortname} config={disqusConfig} />
    </Layout>
  )
}

export const blogPostQuery = graphql`
  query BlogPostQuery($slug: String!) {
    contentfulBlogPost(slug: { eq: $slug }) {
      body {
        childMarkdownRemark {
          html
        }
      }
      title
      updatedAt
    }
  }
`

export default BlogPostPage
